import request from "../utils/request";

// 分页获取字典列表
export function query(params) {
    return request({
        url: "/vc-teacher",
        method: "GET",
        params
    })
}


// 修改数据
export function editRequest(params) {
    return request({
        url: "/vc-teacher",
        method: "PUT",
        data: params
    })
}

// 上传背景图片
export function upload(params) {
    return request({
        url: "/vc-teacher/upload",
        method: "POST",
        data: params
    })
}

