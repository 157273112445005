<template>
  <div class="tinymce-editor">
    <img src="" style="width: 100px"/>

    <div style="border: 1px solid #ccc;">
      <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
      <Editor
          style="height: 600px; "
          v-model="editorText"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="onCreated"
      />
    </div>
    <el-button @click="editUser">保存</el-button>

  </div>
</template>
<script>
import Vue from 'vue'
import { IToolbarConfig } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {editRequest, query, upload} from '@/api/teach.js'
import { uploadBackground } from '@/api/background.js'
// import { ACCESS_TOKEN } from '@/store/mutation-types'
import axios from 'axios'
// import JEditor from './JEditor.vue'
export default Vue.extend({
  components: { Editor, Toolbar },
  // props: ['dataObj'],
  data() {
    return{
      editor: null,
      editorText: '',
      toolbarConfig: {
        excludeKeys: ['fullScreen']
        // insertKeys: ['']
      },
      editorConfig: {
        placeholder: '请输入内容',
        MENU_CONF: {
          // 上传图片
          uploadImage: {
            customUpload(file, insertFn) {
              let formData =new FormData();
              formData.append("bgFile", file)
              upload(formData).then((res) => {
                if (res.code === 0) {
                  console.log(res.data.url)
                  insertFn(res.data.url)
                } else {
                  this.$message.error("上传失败，请稍后重试")
                }
              })
            }
          },
          uploadVideo: {
            // 最多可上传几个文件，默认为 5
            maxNumberOfFiles: 1,
            customUpload(file, insertFn) {
              // if (!file.type.includes("video")) {
              //   return proxy.$modal.msgError("请上传视频格式的文件！");
              // } else if (file.size / 1024 / 1024 > 200) {
              //   return proxy.$modal.msgError("请上传200M以内的文件！");
              // }
              let formData =new FormData();
              formData.append("bgFile", file)
              upload(formData).then((res) => {
                if (res.code === 0) {
                  insertFn(res.data.url)
                  // insertFn("https://wendu.changyangdt.com/qr/zyQR/666.mp4")
                } else {
                  this.$message.error("上传失败，请稍后重试")
                }
              })
            }

          }
        }
      },
      mode: 'default' // default or 'simple'
    }
  },
  watch: {
    editorText(newVal) {
      console.log('富文本更新数据', newVal)
      // setTimeout(() => {
      //   this.$emit('updateData', newVal, this.dataObj.model, this.dataObj.type)
      // }, 500)
    }
  },
  methods: {
    refresh() {
      query().then((res) => {
        this.editorText = res.data.content
        console.log(' this.content', this.content)
      })
    },

    editUser() {
      const params = {
        content: this.editorText,
      }
      editRequest(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("修改成功")
          this.refresh();
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    }
  },
  mounted() {
    this.refresh()
    console.log('fff',IToolbarConfig)
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }

})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
