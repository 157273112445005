import request from "../utils/request";

// 上传背景图片
export function uploadBackground(params) {
    return request({
        url: "/vc-background/upload",
        method: "POST",
        data: params
    })
}

// 获取背景图片列表
export function queryBackground(params) {
    return request({
        url: "/vc-background/page",
        method: "GET",
        params
    })
}

// 删除背景图片
export function del(id) {
    return request({
        url: "/vc-background/" + id,
        method: "DELETE"
    })
}